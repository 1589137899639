import React from "react"
import Helmet from "react-helmet"
import { Link } from 'gatsby'
import { graphql } from "gatsby"
import '../assets/index.scss'
import styles from './itinerary.module.scss';
import get from "lodash/get";
const logo = require('../assets/images/minjLogo.png')
import Modal from "../components/Modal";
import {FacebookIcon, TwitterIcon, TwitterShareButton, GooglePlusShareButton, GooglePlusIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton} from "react-share";

export default class Itinerary extends React.Component {
    constructor(props) {
        super(props);
        this.modal = React.createRef();
    }

    modalToggle = (e) => {
        console.log(e.target.dataset.package)
        this.modal.current.modalToggle(e.target.dataset.package);
    };
    render(){
        const itinerary = get(
            this,
            'props.data.markdownRemark'
        )
        const slug = get( this,'props.pageContext.slug')
        return (
            <div className={styles.iWrapper + " text-montserrat"}>
                <Helmet title={`${itinerary.frontmatter.title} | Minj Tour & Travels`} />
                <aside className={styles.sidebar + " menu"}>
                    <p className="menu-label has-padding-left-md">
                        Navigation
                    </p>
                    <ul className="menu-list">
                        <li><Link to="/" className=""><i className="fas fa-home"/> Home</Link></li>
                        <li><Link to="/packages"><i className="fas fa-clipboard-list"/> View all</Link></li>
                        <li>
                            <div className={styles.tourLink}>
                                <a data-package={itinerary.frontmatter.name} onClick={(e) => this.modalToggle(e)}>Book Now</a>
                            </div>
                        </li>
                    </ul>
                </aside>
                <div className={styles.shareButtons}>
                    <ul className="menu-list">
                        <li>
                            <FacebookShareButton quote="Checkout this Awesome Travel Package I Found.." url={'https://minjtourntravels.com'+ slug} className="has-text-white-bis" >
                                <FacebookIcon round size={32}/>
                            </FacebookShareButton>
                        </li>
                        <li>
                            <WhatsappShareButton url={'https://minjtourntravels.com'+ slug} className="has-text-white-bis" >
                                <WhatsappIcon round size={32}/>
                            </WhatsappShareButton>
                        </li>
                        <li>
                            <TwitterShareButton url={'https://minjtourntravels.com'+ slug} className="has-text-white-bis" >
                                <TwitterIcon round size={32}/>
                            </TwitterShareButton>
                        </li>
                        <li>
                            <GooglePlusShareButton url={'https://minjtourntravels.com'+ slug} className="has-text-white-bis" >
                                <GooglePlusIcon round size={32}/>
                            </GooglePlusShareButton>
                        </li>
                    </ul>
                </div>
                <div className={styles.itinerary}>
                    <img src={logo} alt=""/>
                    <address>Catholic Charities Building,<br/> Dr. Camil Bluke Path(Purulia Road), Ranchi, Jharkhand</address>
                    <p className="has-text-centered">Email - bimal@minjtourntravels.com Ph- <strong>8709285656, 9955120517, 8580361618</strong></p>
                    <h1 className="heading is-size-2 has-text-centered has-color-primary">{itinerary.frontmatter.name}</h1>
                    {
                        (itinerary.frontmatter.type === 'pilgrimage') ? <h2 className="subtitle has-text-centered is-uppercase is-marginless">Next on :<strong> {itinerary.frontmatter.tour} , 2019 [ {itinerary.frontmatter.travel} Days ]</strong></h2> : null
                    }
                    <h2 className="subtitle has-text-centered">TOUR COST : <strong>
                        {
                            (itinerary.frontmatter.cost === 'TBA') ? 'Please Contact for the Price' : ('₹ ' + itinerary.frontmatter.cost)
                        }
                        </strong></h2>
                    <div className="columns is-multiline is-fullwidth has-text-centered">
                        {
                            (itinerary.frontmatter.places[0] !== 'TBA') ? (
                                itinerary.frontmatter.places.map((place, index) => <h2 key={index} className="column is-size-4 has-color-accent">{place}</h2>)
                            ) : null
                            }
                    </div>
                    <div
                        className="blog-post-content"
                        dangerouslySetInnerHTML={{ __html: itinerary.html }}
                    />
                </div>
                <Modal ref={this.modal}/>
            </div>
        )
    }
}

export const pageQuery = graphql`
  query ItineraryBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        name
        type
        date(formatString: "MMMM DD, YYYY")
        places
        tour
        travel
        cost
      }
    }
  }
`