import React from 'react'
import styles from './modal.module.scss'

export default class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            modal: false,
            mailSent: false,
            mailing: false,
            error: null,
            formData: {
                travel: '',
                name: '',
                phone: '',
                email: '',
                people: '',
                message: ''
            }
        }
    }

    modalToggle = name => {
        const { formData } = this.state;
        this.setState({modal: !this.state.modal, formData: {...formData,travel: name}})
    };

    handleChange = (e) => {
        const { formData } = this.state;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value
            }
        })
    }

    handleFormSubmit = e => {
        if (!e.target.checkValidity()) {
            return;
        }
        e.preventDefault();
        this.setState({
            mailSent: false,
            mailing: true
        });
        // const formData = new FormData(this.form)
        let formData = this.state.formData
        const _this = this;
        fetch("http://minjtourntravels.com/mail.php", {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
                if (response.ok) {
                    _this.setState({
                        mailSent: true,
                        mailing: false
                    })
                }else{
                    throw new Error("Failed to send Message try again.")
                }
            }).catch((error) => {
                _this.setState({mailing: false, error: error.message})
                console.log("Request failed", error);
            });
    };

    render() {

        return (
            <div className={styles.modal} data-status={this.state.modal}>
                <div className="is-overlay" onClick={() => this.modalToggle(null)}/>
                <div id="login">
                    <div className="login-card">
                        <div className="card-title">
                            <h1>Please Fill in the Details We will contact you</h1>
                            <h3>Package Chosen: <span
                                className="has-color-accent has-text-weight-bold">{this.state.formData.travel}</span>
                            </h3>
                        </div>
                        <div className="content">
                            <form method="POST" ref={this.form} onSubmit={this.handleFormSubmit}>
                                <input className="is-hidden is-invisible" name="travel"
                                       defaultValue={this.state.formData.travel}/>
                                <input className="inputBox" onChange={(e) => this.handleChange(e)} id="name" type="text"
                                       name="name" title="name" placeholder="Full Name" required/>
                                <input className="inputBox" onChange={(e) => this.handleChange(e)} id="email"
                                       type="email" name="email" title="email" placeholder="Email"/>
                                <input className="inputBox" onChange={(e) => this.handleChange(e)} id="phone"
                                       type="phone" name="phone" title="phone" placeholder="Contact No." required/>
                                <input className="inputBox" onChange={(e) => this.handleChange(e)} id="people"
                                       type="number" name="people" title="people" placeholder="No. of Persons" min="1"
                                       max="100" required/>
                                <textarea rows="4" className="inputBox full" onChange={(e) => this.handleChange(e)} id="message" name="message" title="message"
                                          placeholder="Query or Message" required/>
                                <button type="submit" className={(this.state.mailing) ? 'button is-loading': "button"}>
                                    Submit
                                </button>
                                {this.state.mailSent &&
                                    <div className="has-text-success has-text-centered">We have received your message and will contact you soon as possible.</div>
                                }
                                {this.state.error &&
                                    <div className="has-text-warning">{this.state.error}</div>
                                }
                            </form>
                        </div>
                    </div>
                    <button onClick={() => this.modalToggle(null)} className="close">
                        <i className="fas fa-times is-size-2 has-color-accent"/>
                    </button>
                </div>
            </div>
        )
    }
}